// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.link
  box-sizing: border-box

  &:not(.allow-wrap)
    white-space: nowrap

  &:not(.primary):not(.secondary)
    text-decoration: none

  &.primary
    color: var(--c-text-brand-normal)

    &:active:not(:disabled),
    &:hover:not(:disabled)
      color: var(--c-text-brand-normal-hover)
      text-decoration: underline

    &.link-visited
      &:visited
        color: var(--c-text-brand-normal-active)

  &.secondary
    color: var(--c-text-neutral-light)
    &:active:not(:disabled),
    &:hover:not(:disabled)
      color: var(--c-text-neutral-heavy)

    &.link-visited
      &:visited
        color: var(--c-text-neutral-heavy)

  // stylelint-disable declaration-no-important
  &:disabled
    cursor: not-allowed !important
    background: transparent
    border: none

  &.primary, &.secondary
    &:disabled
      opacity: .5

.doc-link
  align-items: center

  &:not(.allow-wrap)
    white-space: nowrap

.icon
  nudge('up', 2px)
  margin-left: .11rem
  margin-right: .1rem
  font-size: .9rem

.doc-icon
  nudge('down', 4px)
  margin-right: $cs.xxs
