// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  position: relative

.message
  vertical-align: middle

.expand-icon
  position: absolute
  right: $cs.xs
  top: 50%
  transform: translateY(-50%)
  transition: transform $ad.m ease-in-out

  &-open
    transform: translateY(-50%) rotate(180deg)

.link
  transition: color 50ms linear
  border-radius: $br.m
  text-decoration: none
  color: var(--c-text-neutral-semilight)
  display: flex
  align-items: center
  gap: $cs.xs
  padding: $cs.xs
  position: relative
  box-sizing: border-box
  width: 100%
  padding-left: $cs.xs

  .title
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  &.active
    background: var(--c-bg-brand-semilight)
    color: var(--c-text-brand-normal)

    .icon
      color: inherit

  &.disabled
    color: var(--c-text-neutral-extralight)

    .icon
      color: inherit

  &:hover:not(&.active)
    color: var(--c-text-neutral-heavy)
    background: var(--c-bg-neutral-light)

    .icon
      color: inherit
