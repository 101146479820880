// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

$border-width = $cs.xxs

left-border($color)
  background: linear-gradient(to right, $color, $color 5px, var(--c-bg-neutral-min) 0px)

.notification
  left-border(var(--c-border-neutral-normal))
  border-top: 1px solid var(--c-border-neutral-light)
  border-right: 1px solid var(--c-border-neutral-light)
  border-color: var(--c-border-neutral-extralight)
  border-radius: $br.m
  margin-bottom: $ls.xxs
  box-shadow: 0 1px 1px 0 var(--c-bg-neutral-light)
  display: flex
  flex-wrap: wrap
  padding: 1px

  &.small
    border-left-width: $border-width

    .icon
      margin-right: $cs.m - $border-width
      margin-left: 0

    .container
      margin: $cs.s $cs.s $cs.s $cs.l

  &.with-details
    .container
      margin-right: 8rem

  &.error
    left-border(var(--c-bg-error-normal))
    color: var(--c-bg-error-normal)

  &.warning
    left-border(var(--c-bg-warning-normal))
    color: var(--c-text-warning-normal)

  &.info
    left-border(var(--c-text-brand-normal))
    color: var(--c-text-brand-normal)

  &.success
    left-border(var(--c-bg-success-normal))
    color: var(--c-bg-success-normal)

  .message
    display: flex
    align-items: flex-start
    flex-direction: column
    gap: $cs.xs

  .title
    text-margin-bottom($cs.s)
    margin-top: 0
    font-weight: $fw.bold

  .content
    display: inline-block
    color: var(--c-text-neutral-heavy)

  .container
    display: flex
    margin: $cs.xl

  .icon
    flex-shrink: 0
    display: flex
    margin-right: $cs.xl - $border-width
    margin-left: $cs.m
    margin-top: .1rem
