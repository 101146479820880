// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

make-variables($name, $theme)
  $dict = lookup('$' + $name)
  for $key, $value in $dict[$theme][color]
    {'--c-'}{$key}: $value
  for $key, $value in $dict[$theme][shadow]
    {'--shadow-'}{$key}: $value

// Generate CSS variables based on token definitions.

:root, :before, :after
  make-variables('tokens', 'theme-light')

  --grid-column-gap: $ls.s
  --grid-row-gap: $ls.s
  --container-vertical-padding: $ls.s
