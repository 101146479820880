// Copyright © 2024 The Things Industries B.V.

.panel
  height: 100%
  display: flex
  flex-direction: column
  position: relative

  .content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 100%
    min-height: 10rem

.status-container
  +media-query-min($bp.sm)
    flex-direction: column
    align-items: flex-start

  +media-query-min(1500px)
    flex-direction: row
    align-items: center
