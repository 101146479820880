// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.uplink, .downlink
  div
    width: 1.2rem
    height: 1.2rem
    display: flex
    color: var(--c-bg-neutral-min)
    border-radius: 100%
    justify-content: center
    align-items: center
    font-size: $fs.s
    font-weight: $fw.bolder

    &:not(.active)
      border-normal()
      box-sizing: border-box
      color: var(--c-text-neutral-light)

    &.active
      background-color: var(--c-bg-success-normal)

.icon
  color: var(--c-text-neutral-light)
