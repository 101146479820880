// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.status-label
  display: flex
  height: fit-content
  align-items: center
  padding: $cs.xxs $cs.s $cs.xxs $cs.s
  width: fit-content
  border-radius: $br.m
  justify-content: center
  gap: $cs.xxs

  &.no-content
    padding: $cs.xs
    gap: 0


  .message
    font-weight: $fw.bold
    white-space: nowrap
  .link > span
    text-decoration: underline

.error
  background-color: var(--c-bg-error-light)
  color: var(--c-text-error-bold)
  border: 1px solid var(--c-border-error-light)
  .link
    color: var(--c-text-error-bold)
    &:hover
      color: var(--c-text-error-normal-hover)

.success
  background-color: var(--c-bg-success-light)
  color: var(--c-text-success-bold)
  border: 1px solid var(--c-border-success-light)
  .link
    color: var(--c-text-success-bold)
    &:hover
      color: var(--c-text-success-normal-hover)

.warning
  background-color: var(--c-bg-warning-light)
  color: var(--c-text-warning-bold)
  border: 1px solid var(--c-border-warning-light)
  .link
    color: var(--c-text-warning-bold)
    &:hover
      color: var(--c-text-warning-normal-hover)

.info
  background-color: var(--c-bg-info-light)
  color: var(--c-text-info-bold)
  border: 1px solid var(--c-border-info-light)
  .link
    color: var(--c-text-info-bold)
    &:hover
      color: var(--c-text-info-normal-hover)
