// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.left
  flex-basis: 65%
  padding-right: $cs.m
  overflow-y: scroll
  border-normal('right')

  +media-query($bp.sm)
    flex-basis: 100%
    flex-grow: 0
    margin-bottom: $ls.m
    border: 0

  h4
    margin-top: 0

  ul
    text-margin-top()
    padding-left: 0

  li
    display: flex
    align-items: flex-start

  li > span
    nudge('down', 3px)
    color: var(--c-bg-info-normal)
    margin-right: $cs.xxs

  .right-name
    color: var(--c-text-neutral-heavy)

.right
  .secret-inspector
    width: 15rem

  .description
    margin: 0

  +media-query($bp.sm)
    flex-grow: 0

    .secret-inspector
      width: 100%

  +media-query-min($bp.sm)
    flex-basis: 35%
    padding-left: 3rem
