// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.wrapper
  cursor: pointer
  display: flex
  flex-wrap: nowrap
  justify-content: flex-start
  align-items: center

  &:not(.disabled):hover input ~ .dot
    background-color: var(--c-bg-neutral-semilight)

  &:not(.disabled):hover input:checked ~ .dot, input:checked:focus ~ .dot
    border-color: var(--c-text-brand-normal-hover)

.disabled
  cursor: not-allowed
  color: var(--c-text-neutral-light)

  .dot
    opacity: .4

.radio
  nudge('up')
  position: relative
  display: inline-block
  white-space: nowrap
  vertical-align: middle
  outline: 0

  input
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 1
    width: 100%
    height: 100%
    cursor: pointer
    opacity: 0

    &:disabled
      cursor: not-allowed

  & input:checked ~ .dot
    border-color: var(--c-border-neutral-semibold)

  input:checked ~ .dot:after
    opacity: 1
    transform: scale(1)
    transition: all $ad.s cubic-bezier(.12, .4, .29, 1.4) $ad.xs

  input:focus ~ .dot
    border-color: var(--c-text-brand-normal)

.dot
  border-input()
  border-radius: 1.08rem
  position: relative
  top: 0
  left: 0
  display: block
  width: 1.08rem
  height: 1.08rem
  transition: all $ad.s

  &:after
    opacity: 0
    position: absolute
    left: 4px
    top: 4px
    width: 7px
    height: 7px
    background-color: var(--c-text-brand-normal-hover)
    border-radius: 100%
    transform: scale(0)
    transition: all $ad.s ease-out $ad.xs
    content: ''

.label
  padding-right: $cs.xs
  padding-left: $cs.xs
