// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// stylelint-disable

make-utilities($name, $theme)
  $dict = lookup('$' + $name)
  for $key, $val in $dict[$theme][color]
    $value = convert('var(--c-' + $key + ')')
    if split('-', $key)[0] == 'bg'
      .c-{$key}
        background-color: $value !important
      .hover\\:c-{$key}:hover
        background-color: $value !important
      .active\\:c-{$key}:active
        background-color: $value !important
      .forcus\\:c-{$key}:focus
        background-color: $value !important
    else if split('-', $key)[0] == 'text' or split('-', $key)[0] == 'icon'
      .c-{$key}
        color: $value !important
      .hover\\:c-{$key}:hover
        color: $value !important
      .active\\:c-{$key}:active
        color: $value !important
      .focus\\:c-{$key}:focus
        color: $value !important
    else if split('-', $key)[0] == 'border'
      .c-{$key}
        border-color: $value !important
      .hover\\:c-{$key}:hover
        border-color: $value !important
      .active\\:c-{$key}:active
        border-color: $value !important
      .focus\\:c-{$key}:focus
        border-color: $value !important

:global
  make-utilities('tokens', 'theme-light')
