// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  overflow: hidden
  height: 100%
  min-width: 340px

.content
  position: relative
  display: flex
  flex-direction: column
  flex-grow: 1

.main
  overflow-y: scroll

.stage
  display: flex
  flex-direction: column
  flex-grow: 1

.split-frame
  width: 100%
  position: absolute
  bottom: 0
