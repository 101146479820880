// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.wrapper
  border: 1px solid var(--c-border-neutral-light)
  border-radius: $br.l
  transition: border-color .2s
  position: relative
  overflow: hidden
  z-index: 0
  box-sizing: border-box

  &.focus
    :global .ace-ttn
      .ace_marker-layer .ace_active-line
        background-color: var(--c-bg-neutral-light)

      .ace_gutter-active-line
        background-color: var(--c-bg-neutral-semilight)

  &.read-only
    :global .ace-ttn
      .ace_marker-layer .ace_active-line,
      .ace_gutter-active-line,
      .ace_cursor
        display: none

      .ace_bracket
        border: 0
