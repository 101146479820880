// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// stylelint-disable stylus/pythonic

:global {
  .tippy-box {
    z-index: $zi.tooltip
    border-radius: $br.m
    background: var(--c-bg-neutral-heavy)
    color: var(--c-text-neutral-min)
    padding: $cs.m
    max-width: $bp.xs3
    transition: opacity, visibility
    white-space: normal

    &[data-state="hidden"] {
      opacity: 0
    }
    &[data-state="visible"] {
      opacity: 1
    }
  }


  .tippy-arrow,
  .tippy-arrow:before {
    position: absolute
    width: 8px
    height: 8px
    background: inherit
  }

  .tippy-arrow {
    visibility: hidden
  }

  .tippy-arrow:before {
    visibility: visible
    content: ''
    transform: rotate(45deg)
  }

  .tippy-box[data-placement^='top'] > .tippy-arrow {
    bottom: -4px
  }

  .tippy-box[data-placement^='bottom'] > .tippy-arrow {
    top: -4px
  }

  .tippy-box[data-placement^='left'] > .tippy-arrow {
    right: -4px
  }

  .tippy-box[data-placement^='right'] > .tippy-arrow {
    left: -4px
  }
}

// stylelint-enable stylus/pythonic

.small
  padding: $cs.xxs $cs.s
  border-radius: $br.xs
  font-size: $fs.s
