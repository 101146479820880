// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  background-color: var(--c-bg-neutral-bold)
  position: absolute
  bottom: 0
  left: 0
  display: flex
  flex-direction: column
  z-index: $zi.nav
  position: relative
  max-height: "calc(100vh - %s)" % ($header-height)

.header
  position: absolute
  display: flex
  width: 100%
  height: .8rem
  left: 0
  justify-content: center
  align-items: center
  cursor: ns-resize
  flex-shrink: 0
  z-index: $zi.overlay

.handle
  position: absolute
  width: 5rem
  margin: $cs.xs
  border-radius: $br.m
  height: .25rem
  background-color: var(--c-bg-neutral-normal)
  +media-query($bp.md)
    display: none

.content
  display: flex
  flex-grow: 1

.open-button
  position: fixed
  right: 0
  bottom: 0
  padding: $cs.xxs
  width: fit-content

