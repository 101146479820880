// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.search-button
  font-size: $fs.m
  color: var(--c-text-neutral-light)
  width: 100%
  position: relative
  display: flex
  justify-content: space-between
  align-items: center
  padding: $cs.xs
  gap: $cs.xxs
  background: var(--c-bg-neutral-min)
  border: 1px solid var(--c-border-neutral-light)
  border-radius: $br.l
  transition: border-color .08s linear, box-shadow .08s linear, color .08s linear
  height: 2.7rem // ~38px


  &:hover
    color: var(--c-text-neutral-min-hover)

  p
    line-height: 1.4

  .icon
    font-size: $fs.xl
    position: relative

  .keyboard-key
    margin: 0
    height: 1.75rem
    min-width: 1.75rem
    box-sizing: border-box
    text-align: center
    border-radius: $br.m
    border: 1px solid var(--c-border-neutral-normal)
    font-size: $fs.s
    display: flex
    justify-content: center
    align-items: center
    padding: 0 $cs.xxs
