// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.top-entities-panel
  height: 31rem
  display: flex
  flex-direction: column

  &-outer-table-header
    top: 0
    position: sticky
    background: var(--c-bg-neutral-extralight)
    z-index: 1

.scroll-fader
  overflow-y: auto
  width: 100%
  scrollbar-width: none

.table
  table-layout: fixed
