// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.docs-link
  display: flex
  box-sizing: border-box
  transition: color 50ms linear
  text-decoration: none
  color: var(--c-text-neutral-semilight)
  display: flex
  justify-content: space-between
  align-items: center
  padding: $cs.xs 0 $cs.xs $cs.xs
  width: 100%
  border-radius: $br.m
  padding: $cs.s
  transition: background-color $ad.s linear, color $ad.s linear

  &:hover
    background-color: var(--c-bg-neutral-light)
    color: var(--c-text-neutral-heavy)

    .icon
      color: inherit
