// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.badge
  display: flex
  height: fit-content
  align-items: center
  gap: $cs.xxs
  padding: $cs.xxs $cs.s $cs.xxs $cs.s
  width: fit-content
  border-radius: $br.l
  font-size: $fs.l
  font-weight: $fw.bold
  min-width: 3rem
  justify-content: center

.start-icon > svg
  margin-left: - $cs.xxs

.end-icon > svg
  margin-right: - $cs.xxs

.error
  background-color: var(--c-bg-error-light)
  color: var(--c-text-error-bold)

.success
  background-color: var(--c-bg-success-light)
  color: var(--c-text-success-bold)

.warning
  background-color: var(--c-bg-warning-light)
  color: var(--c-text-warning-bold)

.info
  background-color: var(--c-bg-info-light)
  color: var(--c-text-info-bold)
