// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  border-normal(right)
  display: flex
  flex-direction: column
  z-index: $zi.nav
  +media-query-min($bp.sm)
    left: 0
    top: 0
    position: absolute
    height: 100%
  +media-query($bp.sm)
    height: auto
    position: sticky
    top: $header-height-mobile
    z-index: $zi.nav
  +media-query-height(500px)
    position: static

.navigation
  max-height: 100%
  position: relative
  width: 100%
  &.is-minimized
    padding-left: .1rem
    padding-right: .2rem
    margin-right: -.2rem

  &::-webkit-scrollbar-thumb
    visibility: hidden

  &:hover
    &::-webkit-scrollbar-thumb
      visibility: visible

  // Add a gradient to the bottom of the sidebar
  // to signify that there is more content below.
  .scroll-gradient-top, .scroll-gradient-bottom
    position: sticky
    left: 0
    right: 0
    height: 2.5rem
    z-index: $zi.slight
    opacity: 0
    pointer-events: none

  .scroll-gradient-top
    top: 0
    margin-bottom: -2.5rem
    background: linear-gradient(0deg, rgba(255, 255, 255, 0), var(--c-bg-neutral-extralight))

  .scroll-gradient-bottom
    bottom: 0
    margin-top: -2.5rem
    background: linear-gradient(180deg, rgba(255, 255, 255, 0), var(--c-bg-neutral-extralight))

  &-minimized
    +media-query-min($bp.sm)
      width: $sidebar-width-minimized
      display: inline-flex

      .header
        padding: 0
        width: 100%
        display: flex
        height: $breadcrumbs-bar-height
        justify-content: center

        .message
          display: none

        .icon
          margin-right: 0

      .navigation-list
        overflow: visible

      .drawer
        overflow: visible

.drawer
  background-color: var(--c-bg-neutral-min)
  z-index: $zi.nav
  overflow: auto
  +media-query($bp.sm)
    sidebar-transition(left, box-shadow)
    position: fixed
    top: $header-height-mobile
    left: -60vw
    bottom: 0
    width: 60vw
    max-width: 20rem

    &-open
      box-shadow: 0 0 13rem 2rem rgba(0,0,0,.4)
      left: 0

.message
  display: block
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.scroll-lock
  +media-query($bp.sm)
    overflow: hidden
