// Copyright © 2024 The Things Industries B.V.

.panel
  height: 100%
  display: flex
  flex-direction: column
  position: relative

  .content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 100%
    min-height: 10rem

    &-full-width
      gap: $cs.s
      flex-direction: row
      justify-content: start
      min-height: 100%

  .count-div
    line-height: normal
    flex-direction: column

.full-width-icon
  font-size: $fs.xl
  min-width: $cs.l
  background-color: var(--c-bg-brand-extralight)
  padding: $cs.xs
  border-radius: $br.l
  color: var(--c-bg-brand-normal)
