// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.notification-panel
  height: 100%
  height: 31rem
  display: flex
  flex-direction: column

  &-title, &-preview
    font-weight: $fw.bold
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  &-preview
    margin-top: $cs.xxs
    font-weight: $fw.normal
    color: var(--c-text-neutral-light)

  &-total
    color: var(--c-text-neutral-min)
    background-color: var(--c-bg-brand-normal)
    padding: $cs.xxs $cs.xs
    line-height: 1
    border-radius: $br.xxs

.status
  position: absolute
  top: 0
  left: 0

.name-header
  // stylelint-disable-next-line declaration-no-important
  padding-left: $cs.m + $cs.s !important

.scroll-fader
  overflow-y: auto
  width: 100%
  table-layout: fixed
  scrollbar-width: none
