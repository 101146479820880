// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// Add a gradient to the bottom of the sidebar
// to signify that there is more content below.
.scroll-gradient-top, .scroll-gradient-bottom
  position: sticky
  left: 0
  right: 0
  z-index: $zi.slight + 1
  opacity: 0
  pointer-events: none

.scroll-gradient-top
  top: 0
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .05))

  &-light
    background: linear-gradient(0deg, rgba(255, 255, 255, 0), var(--c-bg-neutral-extralight))

.scroll-gradient-bottom
  bottom: 0
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .05))

  &-light
    background: linear-gradient(180deg, rgba(255, 255, 255, 0), var(--c-bg-neutral-extralight))
