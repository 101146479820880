// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.details
  width: 100%
  display: flex
  flex-wrap: wrap
  justify-content: flex-end
  margin-top: -2rem

  &-code
    width: 100%
    background-color: var(--c-bg-neutral-semilight)
    color: var(--c-text-neutral-heavy)
    font-size: $fs.s
    padding: $cs.s
    color: var(--c-text-neutral-heavy)
    margin: (-1 * $ls.m) 0 0 0
    box-sizing: border-box

  &-button
    // stylelint-disable-next-line declaration-no-important
    margin-right: 0 !important
