// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.tab
  display: flex
  flex-direction: column
  border-radius: $br.m
  transition: background-color $ad.xs linear

  &:not(:last-child)
    margin-bottom: 0

  &:hover
    background: var(--c-bg-neutral-semilight)

.tab-item
  display: flex
  align-items: center
  white-space: nowrap
  padding: $cs.m
  gap: $cs.s
  text-align: center
  position: relative
  cursor: pointer
  user-select: none
  transition: color $ad.xs
  color: var(--c-text-neutral-semilight)

  &-toggle-style
    border-radius: $br.m
    padding: 0 $cs.s
    height: 2.25rem
    text-align: center
    border: 1px solid transparent
    transition: background 80ms ease-in-out

    +media-query($bp.xxs)
      padding: 0 $cs.xxs

    &-active
      box-shadow: var(--shadow-box-button-normal)
      background: var(--c-bg-neutral-min)
      font-weight: $fw.normal
      border: 1px solid var(--c-border-neutral-light)
      color: var(--c-text-neutral-heavy)

  &-narrow
    padding: $cs.s $cs.m

  &-disabled
    cursor: not-allowed
    opacity: .4

  &-default:not(.tab-item-active)
    pseudo-border-animated(3px, var(--c-border-neutral-light))

  &-active
    pseudo-border(3px, var(--c-text-brand-normal))
    color: var(--c-text-neutral-heavy)
