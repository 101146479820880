// Copyright © 2022 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.wrapper
  width: 100%
  background: var(--c-bg-neutral-max)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  position: relative
  +media-query($bp.sm)
    background: none
    align-items: start

.capture
  background: var(--c-bg-neutral-semilight)

.capture-wrapper
  padding: $cs.xl
  box-sizing: border-box
  text-align: center

.video
  width: 100%
  max-height: 75vh

  +media-query($bp.xl)
    max-height: 47vh
