// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.row
  display: table-row
  border-radius: $br.s
  color: var(--c-text-neutral-light)
  transition: background-color $ad.xs linear

  &-head:not(.panel-style)
    background: var(--c-bg-neutral-min)

  &-body&:not(:last-child)
    border-bottom: 1px solid var(--c-border-neutral-extralight)

  &-footer
    display: flex

.clickable
  cursor: pointer
  transition: background-color $ad.xs linear
  color: unset

  &:hover
    background: var(--c-bg-neutral-light)
