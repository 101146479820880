// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.switcher-container
  border-radius: $br.l
  border: 1px solid var(--c-border-neutral-light)
  background: var(--c-bg-neutral-min)
  display: flex
  gap: $cs.xxs
  justify-content: center
  padding: $cs.xxs

  .link
    border-radius: $br.m
    text-decoration: none
    font-weight: $fw.bold
    color: var(--c-text-neutral-semilight)
    display: flex
    justify-content: center
    width: 100%
    transition: background 80ms ease-in-out
    position: relative
    padding: $cs.s $cs.xxs

    &.active
      background: var(--c-bg-neutral-heavy)
      color: var(--c-text-neutral-min)
      font-weight: $fw.bold

    &:hover
      text-decoration: none
      &:not(.active)
        background: var(--c-bg-neutral-light)

  .icon
    display: none
    font-size: $fs.xl
    font-weight: 300
