// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  display: flex

.input-box
  border-input()
  input-width-classes()
  box-sizing: border-box
  background: var(--c-bg-neutral-min)
  border-radius: $br.m
  padding: 0
  position: relative
  transition: border-color $ad.s
  overflow: hidden
  overflow-y: hidden
  display: flex
  align-items: center

  &:not(.textarea)
    max-height: $default-input-height

  &.focus
    border-color: var(--c-text-brand-normal)
    color: var(--c-bg-neutral-max)

  &.error
    border-color: var(--c-bg-error-normal)

  &.error.focus
    box-shadow: var(--shadow-box-error-normal)
    box-shadow: 0 0 0 3px var(--c-bg-error-light)

  &.warn
    border-color: var(--c-bg-warning-normal)
    background-color: var(--c-bg-warning-light)

  &.warn.focus
    box-shadow: var(--shadow-box-warning-normal)

  &.disabled
    background-color: var(--c-bg-neutral-semilight)
    opacity: .6

    .input
      cursor: not-allowed

  &.readOnly
    background-color: var(--c-bg-neutral-semilight)

  &.actionable
    margin-right: $cs.xs

::placeholder
  font-family: $font-family
  font-weight: $fw.normal
  color: var(--c-text-neutral-light)
  letter-spacing: initial
  font-variant: normal
  font-size: 1rem

.input
  font: inherit
  background: none
  border: 0
  color: inherit
  outline: 0
  padding: 0 $cs.xs
  display: inline-block
  flex-grow: 2
  z-index: $zi.slight
  height: $default-input-height
  width: 100%

  &[type='password']
    letter-spacing: 1px
    font-variant: small-caption
    font-size: 16px

.hidden
  font-family: text-security-disc
  &:not(.byte)
    -webkit-text-security: disc

.spinner
  padding: 0 $cs.xs
  nudge('down', 3px)

.valid
  height: .8rem
  display: inline-block
  flex-grow: 0
  flex-shrink: 0

  transition: opacity $ad.m
  opacity: 0

  &:last-child
    padding-right: $cs.s

  &.show
    opacity: 1

  path
    fill: var(--c-text-brand-normal)

.byte, .code, .placeholder
  &:not(.hidden)
    font-family: 'IBM Plex Mono', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace

.byte
  font-weight: $fw.bold
  width: 0

.code
  font-size: $fs.s

.placeholder
  position: absolute
  left: .7rem
  white-space: pre
  color: var(--c-text-neutral-light)
  z-index: 0
  pointer-events: none

.icon
  nudge('down')
  padding-left: $cs.xs

textarea.input
  padding: $cs.xs
  min-height: 5rem
  height: auto

.actions
  display: flex

  .button
    max-height: $default-input-height
    border-radius: $br.xs
    height: 100%

  .hide-toggle
    margin-left: -2.5rem

.append
  border-input('left')
  padding: $cs.xxs $cs.xs
  height: 100%
  font-weight: $fw.bold
  display: flex
  align-items: center
  justify-content: center
