// Copyright © 2023 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  padding: 0 $cs.m
  background: var(--c-bg-neutral-extralight)
  z-index: $zi.nav
  display: flex
  justify-content: space-between
  align-items: center
  height: $header-height
  border-normal('bottom')
  +media-query($bp.xs)
    padding: 0 $cs.xs

  &-minimized
    z-index: $zi.mobile-menu + 1

  .notifications-dropdown
    max-width: 31rem
    width: 31rem // Avoids flash of small container when dropdown is opened
    padding: 0

  .bookmarks-dropdown
    max-width: 19.3rem
    width: 19.3rem // Avoids flash of small container when dropdown is opened
    padding: 0

.logo
  height: $cs.l
  +media-query($bp.xs)
    width: 3rem

.divider
  height: 2.5rem
  width: 1px
  background: var(--c-border-neutral-light)
