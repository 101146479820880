// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.list
  border-input()
  input-width-classes()
  position: relative
  box-sizing: border-box
  border-radius: $br.s
  height: 15rem
  min-width: 15rem
  overflow-x: auto
  .wifi-icon
    color: var(--c-icon-neutral-normal)

  .item
    padding: $cs.xxs $cs.xs

    .lock-icon
      color: var(--c-icon-neutral-light)

    &.active
      background-color: var(--c-bg-info-normal)
      color: var(--c-text-neutral-min)

      .lock-icon, .wifi-icon
        color: var(--c-text-neutral-min)
    &:not(.active):hover
      cursor: pointer
      background-color: var(--c-bg-neutral-light)
