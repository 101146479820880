// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.cell
  display: table-cell
  vertical-align: middle
  line-height: 1.15
  padding: 0 'calc(%s / 2)' % $cs.s

  &:first-child
    padding-left: $cs.m

  &:last-child
    padding-right: $cs.m

  &-panel-style
    &:first-child
      padding: 0 'calc(%s / 2)' % $cs.s 0 $cs.s

    &:last-child
      padding: 0 $cs.s 0 'calc(%s / 2)' % $cs.s

  &:not(&-centered):not(&-left)
    &:last-child
      text-align: right
      justify-content: flex-end
      & > *
        justify-content: flex-end

  &-centered
    text-align: center

  &-left
    text-align: left

  &-right
    text-align: right
    justify-content: flex-end
    & > *
      justify-content: flex-end

  &-head
    height: 3rem
    text-transform: uppercase
    font-weight: 600
    font-size: $fs.xs
    color: var(--c-text-neutral-light)
    white-space: nowrap

  &-data
    height: 4rem
    white-space: nowrap

    &:not(:last-child)
      overflow: hidden

    & > *
      overflow: inherit
      text-overflow: ellipsis

    .cell-left
      padding: $cs.l

  &-head:not(&-centered):not(&-right)
    text-align: left
