// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.pagination
  height: 2.5rem
  white-space: nowrap
  padding: 0
  border: 1px solid var(--c-border-neutral-light)
  background: var(--c-bg-neutral-min)
  border-radius: $br.m
  width: fit-content
  display: flex
  align-items: center
  gap: $cs.xxs
  margin: 0

  .item
    margin: 0

.item
  width: $cs.xl
  height: $cs.xl
  display: inline-block
  color: var(--c-text-neutral-heavy)
  user-select: none

  a
    display: inline-block
    cursor: pointer
    height: 100%
    width: 100%

  .link:hover
    background-color: hoverize(var(--c-bg-neutral-extralight-hover))

  .link
    +focus-visible()
      background-color: hoverize(var(--c-bg-neutral-min))

  .link:active
    background-color: activize(var(--c-bg-neutral-min))


  &-disabled
    a
      opacity: .4
      cursor: not-allowed

  &-active
    .link
      background-color: var(--c-text-brand-normal)
      color: var(--c-bg-neutral-min)
      border-radius: $br.m

    .link:hover
      background-color: var(--c-text-brand-normal)

  &-break
    padding: 0 $cs.xxs

  &-icon
    color: var(--c-text-neutral-semilight)
    position: relative
    top: 4px

.link
  outline: 0
  text-align: center
  text-decoration: none
  line-height: 1.9

.size-message
  color: var(--c-text-neutral-semilight)
  white-space: nowrap

.select-size
  :global(.select__value-container)
    padding: 0 0 0 8px
  :global(.select__indicator-separator)
    display: none
  :global(.select__menu)
    color: var(--c-text-neutral-heavy)
  :global(.select__control)
    min-height: 2.5rem
    cursor: pointer
    border-radius: $br.m
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,.045), inset 0px 0px 0px 1px var(--c-bg-neutral-min)
    border: 1px solid transparent
    background: linear-gradient(to bottom, var(--c-bg-neutral-min), var(--c-bg-neutral-min)) padding-box, linear-gradient(to bottom, var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02)) border-box
  :global(.select__control)
    &:hover
      box-shadow: 0px 2px 2px 0px rgba(0,0,0,.045), inset 0px 0px 0px 1px var(--c-bg-neutral-min)
      border-color: transparent
      background: linear-gradient(to bottom, var(--c-bg-neutral-extralight-hover), var(--c-bg-neutral-extralight-hover)) padding-box, linear-gradient(to bottom, var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02)) border-box
