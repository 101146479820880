// Copyright © 2022 The Things Industries B.V.

.section
  border-normal('top')
  padding-top: $ls.xs

  &:not(:first-child)
    +media-query-min($bp.xs)
      margin-top: $ls.m

    +media-query($bp.xs)
      margin-top: $ls.s

  &-head
    +media-query($bp.xs)
      margin-bottom: $ls.xxs
  h3
    +media-query-min($bp.xs)
      margin-bottom: $cs.s

    +media-query($bp.xs)
      margin-bottom: 0
