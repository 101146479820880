// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.toggle
  display: flex
  justify-content: center
  align-items: center
  gap: $cs.xxs
  padding: $cs.xxs
  border-radius: $br.l
  border: 1px solid var(--c-border-neutral-light)
  color: var(--c-text-neutral-semilight)
  background: var(--c-bg-neutral-light)
  box-sizing: border-box
  transition: background-color $ad.xs linear

  &-button
    border-radius: $br.m
    padding: 0 $cs.s
    height: 2.25rem
    font-weight: $fw.bold
    text-align: center
    border: 1px solid transparent
    transition: background 80ms ease-in-out

    +media-query($bp.xxs)
      padding: 0 $cs.xxs

    &-link
      display: flex
      align-items: center
      justify-content: center

    &-active
      box-shadow: var(--shadow-box-button-normal)
      background: var(--c-bg-neutral-min)
      border: 1px solid var(--c-border-neutral-normal)
      color: var(--c-text-neutral-heavy)

    &:hover:not(.toggle-button-active)
      background: var(--c-bg-neutral-semilight)

@container panel (max-width: 560px)
  .toggle
    width: 100%

  .toggle-button
    width: 100%
