// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  display: flex
  align-items: center
  justify-items: flex-start
  flex-wrap: wrap
  margin-bottom: $cs.xxs

  .center
    justify-content: center

.input
  visually-hidden()

.error
  color: var(--c-bg-error-normal)
  &-icon
    color: var(--c-bg-error-normal)
    margin-right: $cs.xxs

.image
  height: 8rem
  width: 8rem
  object-fit: contain
  margin-right: $cs.m

.notification
  flex-basis: 100%


.button-group-center
  flex-wrap: wrap
