// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// We need to use absolute values here due to the way that `react-window` works.
$event-container-height = 40px

.container
  display: flex
  flex-grow: 1
  flex-direction: column
  justify-content: space-between
  position: relative
  overflow: hidden
  height: 100%

  ::-webkit-scrollbar
    width: 1.2rem
    height: 1.2rem
    background-color: none

  ::-webkit-scrollbar-button
    display: none

  ::-webkit-scrollbar-track
    background: none
    border-radius: $br.l

  ::-webkit-scrollbar-thumb
    background: var(--c-border-neutral-semibold)
    border-radius: $br.l
    border: 4px solid transparent
    background-clip: padding-box

  &.framed
    --event-sidebar-header-height: 3rem
    .header
      border-bottom: 0

  &:not(.framed)
    --event-sidebar-header-height: 3rem
    +media-query-min($bp.sm)
      min-height: $event-container-height * 18

    +media-query($bp.sm)
      min-height: $event-container-height * 12

  &:not(.theme-dark)
    --bg-body: var(--c-bg-neutral-extralight)
    --bg-active: var(--c-bg-neutral-min)
    --bg-synthetic: var(--c-bg-neutral-semilight)
    --bg-hover: var(--c-bg-neutral-light)
    --bg-sidebar: var(--c-bg-neutral-min)
    --text-neutral: var(--c-text-neutral-semilight)
    --text-neutral-light: var(--c-text-neutral-light)
    --text-warning: var(--c-text-warning-normal)
    --text-error: var(--c-text-error-normal)
    --c-border: var(--c-border-neutral-bold)
    --scrollbar-track: var(--c-bg-neutral-min)
    --scrollbar-thumb: var(--c-border-neutral-semilight)

  &.theme-dark
    &, :after, :before
      // Reassigned new tokens.
      --bg-body: $c.neutral-700
      --bg-active: $c.neutral-800
      --bg-synthetic: $c.neutral-600
      --bg-hover: $c.neutral-600
      --bg-sidebar: $c.neutral-800
      --text-neutral: $c.white
      --text-neutral-light: $c.neutral-300
      --text-warning: $c.warning-400
      --text-error: $c.error-600
      --c-border: $c.neutral-200
      --scrollbar-track: $c.neutral-800
      --scrollbar-thumb: $c.neutral-500

      // Reassigned existing tokens.
      --c-icon-neutral-light: $c.neutral-500
      --c-bg-neutral-semilight: $c.neutral-800
      --c-bg-success-normal: $c.success-600
      --c-bg-neutral-min: $c.neutral-800
      --c-bg-neutral-light: $c.neutral-700
      --c-bg-neutral-extralight: $c.neutral-050
      --c-border-neutral-normal: $c.neutral-500
      --c-border-neutral-semilight: $c.neutral-800
      --c-border-neutral-light: $c.neutral-700
      --c-border-neutral-extralight: $c.neutral-600
      --c-text-neutral-heavy: $c.neutral-white
      --c-text-neutral-light: $c.neutral-500
      --c-text-info-normal: $c.information-300
      --c-text-success-normal: $c.success-300
      --c-text-brand-normal: $c.tts-300

.header
  background-color: var(--c-bg-neutral-min)
  border-bottom: 1px solid var(--c-border-neutral-light)
  height: 3rem
  z-index: $zi.slight
  box-sizing: border-box
  display: flex
  color: var(--text-neutral)
  position: absolute
  width: 100%

.header-cells
  font-size: $fs.xs
  position: absolute
  width: 100%
  height: 100%
  font-weight: $fw.medium
  .cell-time
    color: var(--text-neutral)
    justify-content: center

  & > div:not(.cell-preview):not(:last-child)
    +media-query($bp.sm)
      display: none

  .cell-data
    +media-query($bp.lg)
      display: none

  & > div
    text-transform: uppercase

.header-cells, .event
  padding: 0 $cs.xxs 0 $cs.xs
  display: flex
  align-items: center
  flex: 1
  justify-content: flex-start

  & > div:not(.cell-preview):not(.cell-error):not(:last-child)
    padding-right: $cs.s
    flex-grow: 0
    flex-shrink: 0
    white-space: nowrap

.sticky-container
  flex-grow: 1
  right: $cs.s
  display: flex
  height: 100%
  align-items: center
  +media-query-min($bp.xs)
    justify-content: flex-end
    position: sticky

.actions
  display: flex
  flex-grow: 1
  justify-content: flex-end
  align-items: center
  right: $cs.xs
  position: absolute
  height: 100%

  button
    margin-right: 0

.pause-button
  width: 5.5rem

.button-bar
  display: flex
  align-items: center
  position: relative
  justify-content: flex-end

.framed-button
  color: var(--text-neutral-light)
  display: flex
  align-items: center
  padding: $cs.xxs
  gap: $cs.xs

  &:hover
    color: var(--text-neutral)

.toggle-container
  display: flex
  align-items: center
  font-size: $fs.s
  color: var(--c-text-neutral-heavy)
  margin-right: $cs.s

.toggle-label
  margin-right: $cs.xs

.widget-container
  border-normal()
  box-sizing: border-box
  min-width: 15rem
  height: 13rem
  overflow: hidden

  .event
    height: 30px

.widget-header-title
  font-weight: $fw.bold

.body
  overflow: hidden
  background-color: var(--bg-body)
  flex-grow: 1
  position: relative
  margin-top: 3rem

  ol > div
    scrollbar-width: thin
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track)

    &::-webkit-scrollbar
      width: 1.2rem
      height: 1.2rem
      background-color: none

    &::-webkit-scrollbar-track
      background: none
      border-radius: $br.l

    &::-webkit-scrollbar-thumb
      background: var(--c-border-neutral-semibold)
      border-radius: $br.l
      border: 4px solid transparent
      background-clip: padding-box

  ol
    padding-left: 0
    margin: 0
    list-style: none

.truncated
  border-normal(top)
  display: flex
  align-items: center
  justify-content: center
  font-size: $fs.s
  color: var(--text-warning)
  padding: $cs.xxs 0

  span
    margin-right: $cs.xxs

.event
  height: $event-container-height
  background-color: transparent
  box-sizing: border-box

  &.active
    background-color: var(--bg-active)
    box-shadow: 0 0 2rem 0 rgba(0,0,0,.1)

  &.widget
    padding: 0 0 0 $cs.xs

    .cell-type
      flex-grow: 1
      overflow: hidden
      text-overflow: ellipsis
      display: flex

  &.synthetic
    background-color: var(--bg-synthetic)
    .cell-time
      color: var(--text-warning)
    .cell-type::before
      content: "Console:"
      margin-right: $cs.xs

  &:not(.widget)
    cursor: pointer
    transition: background-color ease-in-out $ad.xs

    &:hover:not(.active)
      background-color: var(--bg-hover)

  .cell-time, .cell-type, .cell-id, .cell-error
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    font-family: $font-family-mono
    font-size: $fs.s
    color: var(--text-neutral)

  .cell-time
    color: var(--text-neutral-light)

  .cell-type span, .cell-id span
    overflow: hidden
    text-overflow: ellipsis

.cell-time
  flex-basis: 6rem
  display: flex

.cell-id
  flex-basis: 12%
  min-width: 6rem

.event:not(.widget) .cell-type, .header-cells .cell-type
  flex-basis: 17%
  min-width: 14rem
  max-width: 20rem
  display: flex
  +media-query($bp.md)
    display: none

.cell-preview
  color: var(--text-neutral-light)
  flex-basis: auto
  flex-grow: 1
  padding-right: 0
  white-space: nowrap

.cell-data, .cell-error
  flex-grow: 1

.cell-error
  color: var(--text-error)

.event-icon
  margin-right: $cs.xxs

.empty-message
  &-container
    top: 0
    left: 0
    right: 0
    bottom: 0
    position: absolute
    display: flex
    align-items: center
    justify-content: center

  &-content
    color: var(--text-neutral-light)
    pre
      display: inline
      font-size: $fs.s

.sidebar
  &-container
    background-color: var(--bg-sidebar)
    color: var(--c-text-neutral-heavy)
    flex-direction: column
    display: flex

    +media-query-min($bp.sm-md)
      sidebar-transition(right)
      z-index: $zi.nav
      height: 100%
      position: absolute
      width: 43rem
      max-width: 100vw
      right: -100%

      &.expanded
        right: 0
        box-shadow: 0 0 8rem 2rem rgba(0,0,0,.2)

    +media-query($bp.sm-md)
      sidebar-transition(left)
      z-index: $zi.nav
      position: fixed
      width: 100vw
      left: 100vw
      top: $header-height-mobile
      height: "calc(100vh - %s)" % ($header-height-mobile)

      &.expanded
        left: 0vw

  &-header
    display: flex
    flex: 0 0 var(--event-sidebar-header-height)
    justify-content: space-between
    align-items: center
    padding-left: $cs.xs
    box-sizing: border-box
    flex-grow: 0
    border-bottom: 1px solid var(--c-border-neutral-light)
    box-sizing: border-box

  &-content
    flex-grow: 1
    overflow: auto

  &-button
    color: var(--text-neutral-light)
    height: 100%
    padding: $cs.xxs
    display: flex
    align-items: center
    justify-content: center
    position: relative
    top: 1px

    &:hover
      color: var(--text-neutral)

  &-title
    text-transform: uppercase
    font-size: $fs.s
    font-weight: $fw.bold

.body-link
  color: inherit
