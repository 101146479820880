// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.table
  border-collapse: collapse
  table-layout: fixed
  width: 100%
  display: table

  .empty-message
    display: table-caption
    caption-side: bottom
    text-align: center
    margin-top: $ls.xs
    color: var(--c-text-neutral-light)
    font-weight: normal

.section

  // Hacky way of extending the header row by adding a divider between the header and the body.
  &-divider
    position: absolute
    bottom: 0
    left: 0
    right: 0
    height: 1px
    background: var(--c-border-neutral-light)

  &-header
    position: sticky
    z-index: $zi.slight
    top: 0
    display: table-header-group

  &-header:not(.panel-style)
    border-bottom: 1.5px solid var(--c-border-neutral-light)

  &-body
    display: table-row-group

  &-footer
    display: flex
    justify-content: center
