// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  width: 100%

.bar
  width: 100%
  height: .5rem
  border-radius: $br.xxs
  background-color: $c.tts-200

  &.warn
    background-color: var(--c-bg-warning-semilight)

  &.limit
    background-color: var(--c-bg-error-semilight)

.filler
  background-color: var(--c-bg-brand-normal)
  height: 100%
  box-sizing: border-box
  border-radius: $br.xxs
  transition: width $ad.s

  &.warn
    background-color: var(--c-bg-warning-normal)

  &.limit
    background-color: var(--c-bg-error-normal)

.status
  horizontalize()
  div
    display: flex

.progress-bar-values
  display: flex
  justify-content: space-between
  margin-bottom: $cs.xs
