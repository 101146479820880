// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

@require './fonts.styl'
@require './variables/css-variables.styl'

normalize-css()

html, body
  font-size: $font-size-base
  height: 100%

body
  font-family: $font-family
  line-height: $line-height-base
  color: var(--c-text-neutral-heavy)
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  background: var(--c-bg-neutral-min)

:global(#app)
  height: 100%

h1
  h1()

h2
  h2()

h3
  h3()

h4
  h4()

ul li
  &:not(:last-child)
    margin-bottom: $cs.s

  a
    text-decoration: none

hr
  border-normal()
  background-color: var(--c-border-neutral-light)
  border: 0
  height: 1px

code
  font-family: $font-family-mono
  font-size: $fs.s
  background-color: var(--c-bg-neutral-semilight)
  padding: .1rem $cs.xxs
  color: var(--c-text-neutral-heavy)

summary
  display: revert
  cursor: pointer
  text-decoration: underline
  user-select: none


// Remove focus from elements when targeted by a mouse.
// The `focus-visible` class is added on focusable elements
// automatically when in focus.
// See https://github.com/WICG/focus-visible
:global(.js-focus-visible) :focus:not(:global(.focus-visible))
  outline: 0

// Global one-off styles.
:global
  .panel-title
    padding-bottom: $cs.xs
    border-bottom: 1px solid var(--c-border-neutral-light)
