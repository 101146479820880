// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.breadcrumbs
  color: var(--c-text-neutral-light)
  display: flex
  align-items: center
  overflow: hidden

  +media-query($bp.md + 150)
    // If the breadcrumb <= 5 elements
    &:has(.container:nth-child(5))
      .container .breadcrumb
        max-width: 6rem

  +media-query($bp.sm)
    overflow: auto
    margin-right: $ls.xxs * -1
    scrollbar-width: none

    &::-webkit-scrollbar
      display: none

  &-container
    box-sizing: border-box
    height: $breadcrumbs-bar-height
    display: flex
    align-items: center

.container
  display: flex

  +media-query($bp.lg)
    font-size: $fs.s

.link, .last
  max-width: 15rem
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  display: block

.link
  text-decoration: none
  color: var(--c-text-neutral-light)
  align-items: center
  +focus-visible()
    text-decoration: underline
    color: var(--c-text-neutral-heavy)

.last
  align-items: center
  color: var(--c-text-neutral-heavy)
  font-weight: $fw.bold
  +media-query($bp.sm)
    &:last-child
      padding-right: $ls.xxs
