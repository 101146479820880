// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.tabs
  box-sizing: border-box
  list-style-type: none
  padding: 0
  display: flex
  margin: 0
  scrollbar-width: none

  &-toggle-style
    justify-content: center
    align-items: center
    padding: $cs.xxs
    gap: $cs.xxs
    border-radius: $br.l
    border: 1px solid var(--c-border-neutral-light)
    color: var(--c-text-neutral-semilight)
    background: var(--c-bg-neutral-light)

  &::-webkit-scrollbar
    display: none

.divider
  border-bottom: 1px solid var(--c-border-neutral-light)
