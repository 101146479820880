// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.description-list
  font-family: $font-family-mono
  font-size: $fs.s
  margin: 0

.container
  display: inline-flex
  align-items: center

  &:not(:last-child)
    margin-right: $cs.s

  .term
    color: var(--c-text-neutral-light)

    &:after
      content: ':'

  dt
    margin-right: $cs.xs

  dd
    margin-left: 0

.value
  color: var(--text-neutral)
  & > div:not(:last-child)
    margin-right: $cs.s

.empty-byte
  color: var(--c-text-neutral-light)
  font-style: italic

  &:before
    content: '<'
  &:after
    content: '>'

.highlight
  line-height: 1.9rem
  border: 1px solid var(--c-border-neutral-semilight)
  font-family: 'IBM Plex Mono', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace
  display: inline
  background-color: var(--c-bg-neutral-min)
  height: 100%
  padding: 0 $cs.xs
  border-radius: $br.xs
