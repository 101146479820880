// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.dedicated-entity
  text-decoration: none
  line-height: $lh.xs3
  font-weight: $fw.medium
  color: var(--c-text-neutral-heavy)
  position: relative
  height: 2.5rem
  width: 100%

  &-curtain
    position: absolute
    overflow: hidden
    border-radius: $br.l
    width: 2.5rem
    transition: .3s 0s all cubic-bezier(.77, .2, .175, 1)
    top: 0
    left: 0
    z-index: $zi.slight

    &:hover
      transition: .3s .2s all cubic-bezier(.77, .2, .175, 1)
      width: 100%

      // Reveal the button label.
      button span:last-child
        opacity: 1

  &-item
    display: flex
    color: var(--c-text-neutral-heavy)
    opacity: 1
    position: absolute
    left: 2.5rem
    height: 100%
    text-decoration: none

  &-label
    font-size: $fs.m
    font-weight: $fw.normal
    margin: 0 0 0 $cs.xs

  &-divider
    display: list-item
    height: 75%
    margin: 0 $cs.xs 0 $cs.s
    border-left: 1px solid var(--c-border-neutral-light)
    background-color: var(--c-border-neutral-light)

  &-button
    border-radius: $br.l
    padding: 0 $cs.xs 0 .73rem
    background-color: var(--c-bg-neutral-heavy)
    position: relative
    display: inline-flex
    border-radius: $br.l
    outline: 0
    cursor: pointer
    justify-content: center
    align-items: center
    gap: $cs.xxs
    height: 2.5rem
    text-decoration: none
    padding: 0 .75rem 0 .65rem
    white-space: nowrap
    color: var(--c-text-neutral-min)
    width: 100%
    justify-content: flex-start

    svg
      flex-shrink: 0

    span:last-child
      transition: .2s .2s all ease-in-out
      opacity: 0

    &:not(:disabled)
      +focus-visible()
        background-color: var(--c-bg-neutral-light)
