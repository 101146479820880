// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.list
  list-style-type: none
  padding: 0
  margin: 0
  overflow: visible
  font-size: $fs.m

  & > li:not(:last-child)
    margin-bottom: 0

  &-nested > li
    height: 0px
    transition: height $ad.m ease-in-out

  &-expanded > li
    height: 2.4rem

  &-nested
    transition: opacity $ad.m ease-in-out

    &:not(.list-expanded)
      opacity: 0

  &-expanded, &-nested
    opacity: 1
    & li a
      padding-left: $cs.l + $cs.xs

.icon
  margin-right: $cs.xs
