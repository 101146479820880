// Copyright © 2024 The Things Industries B.V.

.panel
  display: flex
  flex-direction: column
  position: relative
  min-height: 28rem
  flex-grow: 1

.content
  position: relative
  height: 100%
  min-height: 15rem
  margin-top: $cs.xl

.chart-date
  color: var(--c-text-neutral-light)
  font-size: $fs.s

