// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.overlay
  visibility: hidden
  opacity: 0
  position: absolute
  min-width: 100%
  min-height: 100%
  height: 100%
  top: 0
  left: 0
  background: var(--c-bg-neutral-min)
  transition: opacity $ad.m ease-in-out $ad.l, visibility $ad.m ease-in-out

  &-visible
    visibility: visible
    opacity: .85
    z-index: $zi.overlay

  &-wrapper
    position: relative

  &-spinner
    z-index: $zi.overlay + 1
