// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  display: flex
  align-items: center
  flex: 0 0 auto

  img
    height: 100%
    width: 100%
    object-fit: contain

  &:not(.unlock-size)
    img:first-child
      height: 1.6rem

    img:last-child
      width: 3rem
      padding: $cs.s 0

  +media-query-min($bp.xs)
    img:first-child
      display: block
    img:last-child
      display: none

  +media-query($bp.xs)
    img:first-child
      display: none
    img:last-child
      display: block
